<template>
    <div>
        <card-header title="Question Responses" icon="fa-clipboard-list-check"/>

        <card-body>
            <card-list>
                <template v-for="g in responseGroups">
                    <subheader-list-item :title="g.last_name + ', ' + g.first_name" icon="fa-user"/>
                    <container-list-item padded>
                        <div v-for="q in g.questions" class="mb-5">
                            <b-field :label="q.question" :message="q.subtext" label-position="inside">
                                <b-input v-if="q.type === 'text'" v-model="q.answer" @blur="save(g, q)"/>
                                <b-input type="textarea" v-if="q.type === 'multi'" v-model="q.answer" @blur="save(g, q)"/>
                                <b-select v-else-if="q.type === 'single'" v-model="q.answer" expanded @input="save(g, q)">
                                    <option :value="o.id" v-for="o in q.options">{{ o.answer }}</option>
                                </b-select>
                            </b-field>
                        </div>
                    </container-list-item>
                </template>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import {client as http} from '../../http_client';

    import CardHeader from '../../TIER/components/CardHeader';
    import CardBody from '../../TIER/components/CardBody';
    import CardList from '../../TIER/components/CardList';
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem.vue";

    export default {
        props: ['card', 'child', 'props'],
        components: {ContainerListItem, SubheaderListItem, CardBody, CardList, CardHeader},
        data: function() {
            return {
                responseGroups: {},
            };
        },
        methods: {
            load: function(force) {
                this.$emit('loading', true);
                http.get('/api/registrations/' + this.props.registrationId + '/responses', {force}).then(response => {
                    this.responseGroups = response.data;
                    this.$emit('loading', false);
                });
            },
            save: function(group, question) {
                let payload = {
                    user_id: group.id,
                    registration_id: parseInt(this.props.registrationId),
                    question_id: question.id
                };

                if (question.type === 'single') {
                    for (let j = 0; j < question.options.length; j++) {
                        const option = question.options[j];
                        if (option.id === question.answer) {
                            payload.answer_id = option.id;
                            payload.response = option.answer;
                            break;
                        }
                    }
                } else {
                    payload.response = question.answer;
                }

                http.post('/api/registrations/' + this.props.registrationId + '/responses', payload).then(response => {

                });
            },
            reload: function() {
                this.load(true);
            }
        },
        mounted() {
            this.load(true);
        }
    };
</script>
